.sidebar {
    min-width: 198px;
    max-width: 206px;
    color: #fff;
    margin-left: -250px;
    transition: all 0.5s;
    background-image: url("https://www.absolut-ticket.at/wp-content/uploads/2021/04/system-3699542_1920-1.jpg");
    background-blend-mode: overlay;
}
.hidden-ul-bullets li {
    list-style: none;
}
.hidden-ul-bullets ul {
    margin-left: 0.25em;
}
.sidebar a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;

}
.headerTop{
    background-blend-mode: overlay;
    background-color: #008099;
    background-image: url("https://www.absolut-ticket.at/wp-content/uploads/2021/04/system-3699542_1920-1.jpg");
}
.sidebar-header {
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
    opacity: 1;
    border-bottom: 1px solid white;
    margin-bottom: 1px !important;

}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.menu-open {
    background: #6d7fcc;

}

.nav-item:hover {
    color: #7386d5;
    background: #fff;
}

.items-menu {
    color: #fff;
    background: #6d7fcc;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}
.side-menu li{
    margin-top: 20px;
    font-size: 23px;
}
.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}
