.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}
body {
    font-family: "Poppins", sans-serif;
    background: #fafafa;
}


#containerCard{
    margin: auto;
    margin-top: 18px;
    padding: 20px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #F7F7F7;
}
